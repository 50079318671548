import React from "react"
import {
    FaLinkedin,
    FaGithubSquare,
    FaStackOverflow,
    FaFreeCodeCamp,
    FaTwitterSquare,
    FaMedium
} from "react-icons/fa"

const config = require('../../../config')


const SocialLinks = ({ contacts }) => {
    return (
        <div className="social-links float-right mr-4">
            <a className="text-primary ml-4"
                href={contacts.linkedin}>
                <span title="Linked In">
                    <FaLinkedin size={40} style={{ color: "white" }} />
                </span>
            </a>
            <a className="text-light ml-4"
                href={contacts.github}>
                <span title="GitHub">
                    <FaGithubSquare size={40} style={{ color: "light" }} />
                </span>
            </a>
            <a className="text-info ml-4"
                href={contacts.twitter}>
                <span title="Twitter">
                    <FaTwitterSquare size={40} style={{ color: "white" }} />
                </span>
            </a>
            <a className="text-info ml-4"
                href={contacts.medium}>
                <span title="Medium">
                    <FaMedium size={40} style={{ color: "white" }} />
                </span>
            </a>
        </div>
    )
}

export default SocialLinks